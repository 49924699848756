import React from 'react'

import css from './style.module.scss'

export default function HolidaysOfMonth() {
  const calendar = [
    {
      title: 'Среда, 5 апреля',
      description: 'Вечером наступает праздник, проводится первый Седер и едят мацу.',
    },
    {
      title: 'Четверг, 6 апреля',
      description: 'Вечером проводят второй Седер и едят мацу.',
    },
    {
      title: 'Суббота, 8 апреля',
      description: 'Шаббат',
    },
    {
      title: 'Воскресенье, 9 апреля - вторник, 11 апреля',
      description: 'Холь-Амоэд, будни праздника',
    },
    {
      title: 'Среда, 12 апреля',
      description: 'Праздник, седьмой день Песаха',
    },
    {
      title: 'Четверг, 13 апреля',
      description: 'Праздник, последний день Песаха. Читается поминальная молитва «Изкор». Принято устраивать «Трапезу Мошиаха»',
    },
  ]
  
  return (
    <div className={css.calendar}>
      <div className='container'>
        <div className={css.content}>
          <div className={css.header}>
            <div className={css.headerTitle}>
              <h2 className={css.title}>Календарь<br/>Песаха</h2>
              <h2 className={css.year}>5783<br/>2023</h2>
            </div>
          </div>
          <div
            className={css.cards}
          >
            {
              calendar.map((item, index) => (
                <div
                  className={css.card}
                  key={index}
                >
                  <div className={css.cardTitleWrapper}>
                    <h4 className={css.cardTitle}>{ item.title }</h4>
                  </div>
                  <div className={css.cardDescriptionWrapper}>
                    <p className={css.cardDescription}>{ item.description }</p>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  )
}