import React from 'react'
import css from './style.module.scss'
import { Button } from 'components/ui'
import cn from 'classnames'
import { Link } from 'gatsby'

export default function HeroView({
  hasOrder,
}) {
  return (
    <div className={css.hero}>
      <div className={cn(css.container, 'container')}>
        <div className={css.textWrap}>
          <h1 className={css.title}>МАЦА ШМУРА</h1>
          <br/>
          <h2 className={css.subtitle}>ручной работы к празднику Песах</h2>
          {
            hasOrder &&
              <p className={css.descr}>
                Оставьте заявку и получите мацу ручной работы к празднику
              </p>
          }
          {
            !hasOrder &&
              <p className={css.descr}>
                Заявки больше не принимаются
              </p>
          }
          {
            hasOrder &&
              <React.Fragment>
                <div className={css.buttonWrap}>
                  <Link to='/make-order/'>
                    <Button
                      className={css.button}
                      text="Оставить заявку"
                    />
                  </Link>
                </div>
                <p className={css.dueDate}>
                  Заявки принимаются <b>до 2 апреля 2023</b>
                </p>
              </React.Fragment>
          }
        </div>
      </div>
    </div>
  )
}
