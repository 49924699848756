import React from 'react'

import Header from './components/Header/Container'
import Footer from './components/Footer/Container'
import PopupRoot from 'components/popups/PopupRoot'
import "libs/scss/main.scss"
import css from './style.module.scss'

export default function MainLayout({
  children
}) {
  return (
    <div className={css.pageWrap}>
      <PopupRoot />
      <div className={css.headWrap}>
        <Header />
      </div>
      <div className={css.content}>
        {children}
      </div>
      <Footer />
    </div>
  )
}

export const withMainLayout = (WrappedComponent) => {
  return props => (
    <MainLayout>
      <WrappedComponent {...props} />
    </MainLayout>
  )
}
