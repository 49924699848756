import React from 'react'
import css from './style.module.scss'

export default function () {

  return (
    <div className={css.whatIs}>
      <div className='container'>
        <div className={css.content}>
          <div className={css.text}>
            <h2 className={css.title}>Праздник Песах 5783</h2>
            <h4 className={css.subtitle}>&nbsp;5 апреля 2023 - 13 апреля 2023&nbsp;</h4>
            <p className={css.description}>
              Песах – праздник Исхода из Египта. Праздник Песах можно назвать временем рождения еврейского народа. В дни Песаха мы отмечаем освобождение от рабства – как физического, так и духовного. Праздник продолжается восемь дней.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
