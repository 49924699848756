import React from 'react'
import { Link } from 'gatsby'
import { Container, Row, Col } from 'styled-bootstrap-grid'

import { IconLinkAway } from 'components/icons'
import matza_1_1x from './img/matza_1@1x.jpg'
import matza_1_2x from './img/matza_1@2x.jpg'
import matza_2_1x from './img/matza_2@1x.jpg'
import matza_2_2x from './img/matza_2@2x.jpg'
import matza_3_1x from './img/matza_3@1x.jpg'
import matza_3_2x from './img/matza_3@2x.jpg'
import matza_4_1x from './img/matza_4@1x.jpg'
import matza_4_2x from './img/matza_4@2x.jpg'
import css from './style.module.scss'

const data = [
  {
    title: 'Что такое МАЦА ШМУРА?',
    description: <React.Fragment>
      <p>Если смешать муку с водой, через 18 минут начинается процесс брожения. Тесто взойдет и станет квасным – хамец. Поэтому изготовление мацы от замешивания теста и до полной готовности не должно превышать 18 минут. Есть и особая маца – шмура. Для нее муку особенно оберегают и следят, чтобы уже с момента помола она не соприкасалась с любой жидкостью или влагой.</p>
      <br/>
      <p>Более того: иногда следят, чтобы пшеница уже с момента жатвы не соприкасалась с водой и другой влагой. На упаковке обычно указывается, с какого момента маца оберегается: с жатвы или с помола.</p>
    </React.Fragment>,
    x1: matza_1_1x,
    x2: matza_1_2x
  },
  {
    title: 'Что такое маца ручной работы?',
    description: <React.Fragment>
      <p>Вся работа выполняется вручную. Евреи вручную смешивают муку с водой, раскатывают тесто, делают в нем отверстия, кладут в печь и достают оттуда готовую мацу.</p>
      <br/>
      <p>Еврейский закон высоко ценит мысли и намерения, с которыми человек исполняет заповедь. Готовя мацу вручную, человек думает о том, что делает это во имя исполнения заповеди. Машина думать не может.</p>
    </React.Fragment>,
    x1: matza_2_1x,
    x2: matza_2_2x
  },
  {
    title: 'Как определить мацу шмуру ручной работы?',
    description: <React.Fragment>
      <p>Маца квадратной формы делается автоматически. Маца ручной работы получается круглой, и она обычно является шмурой. При этом важно удостовериться, что маца изготовлена под надзором авторитетного органа, что она соответствует самым высоким стандартам кошерности.</p>
    </React.Fragment>,
    x1: matza_3_1x,
    x2: matza_3_2x
  },
  {
    title: 'Сколько мацы надо съесть на пасхальном Седере?',
    description: <React.Fragment>
      <p>Хотя бы 27 грамм, примерно половину листа круглой мацы. В Каббале маца называется Хлебом веры и исцеления.</p>
    </React.Fragment>,
    x1: matza_4_1x,
    x2: matza_4_2x
  },
]

export default function() {
  return (
    <div className='container'>
      <div className={css.holidaySet}>
        <Container>
          {
            data.map((item, idx) => (
              <Row key={ idx } alignItems='center' className={css.itemRow}>
                <Col col='12' lg='6' order='0' lgOrder={ idx % 2 + 1 }>
                  <div>
                    <h2>{ item.title }</h2>
                    <br/>
                    <div className={css.description}>
                      { item.description }
                    </div>
                  </div>
                </Col>
                <Col col='12' lg='6' order='1' lgOrder={ idx % 2 }>
                  <img
                    src={ item.x1 }
                    srcSet={`${ item.x1 } 1x, ${ item.x2 } 2x`}
                    className={css.image}
                    alt=''
                  />
                </Col>
              </Row>
            ))
          }
          <Row justifyContent='center'>
            <Col col='12' md='6' xl='4'>
              <Link
                to='/make-order/'
                className='button-blue label'
              >
                Получить набор к празднику
              </Link>
            </Col>
          </Row>
          <br/>
          <Row justifyContent='center'>
            <Col col='auto'>
              <a
                href='https://ru.chabad.org/library/article_cdo/aid/558079'
                target='_blank'
                rel='noopener noreferrer'
                className='blue label'
              >
                Узнайте больше о празднике
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}