import React, { useState, useEffect } from 'react'
import HeaderView from './View'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { openLogInPopup } from 'components/user/actions'
import { ordersIsAvailable } from 'components/orders/actions'
import PropTypes from 'prop-types'

HeaderContainer.propTypes = {
  openLogInPopup: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  openLogInPopup: bindActionCreators(openLogInPopup, dispatch),
  ordersIsAvailable: bindActionCreators(ordersIsAvailable, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderContainer)

function HeaderContainer({
  openLogInPopup,
  ordersIsAvailable,
  user,
}) {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(!!user.authToken) /*was null*/ 
  const onClickLogInHandler = () => openLogInPopup()

  useEffect(() => {
    setIsUserLoggedIn(!!user.authToken)
  }, [user])

  /* TODO: move this call to gatsby-browser.js */
  useEffect(() => {
    ordersIsAvailable()
  }, [])

  return <HeaderView
    onClickLogIn={onClickLogInHandler}
    isUserLoggedIn={isUserLoggedIn}
  />
}
