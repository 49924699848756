import React, { useState, useEffect } from 'react'
import MobileMenu from './MobileMenu'
import { connect, useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { openLogInPopup, logout } from 'components/user/actions'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

MobileHeaderContainer.propTypes = {
  openLogInPopup: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  user: state.user,
  authToken: state.user.authToken,
  hasOrder: state.orders.has_order,
})

const mapDispatchToProps = dispatch => ({
  openLogInPopup: bindActionCreators(openLogInPopup, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileHeaderContainer)

function MobileHeaderContainer({
  openLogInPopup,
  user,
  authToken,
  hasOrder,
}) {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(null)

  const onClickLogInHandler = () => openLogInPopup()

  useEffect(() => {
    setIsUserLoggedIn(!!user.authToken)
  }, [user])

  const dispatch = useDispatch()

  function onClickLogoutHandler() {
    dispatch(logout())
  }

  const onClickOrderHandler = e => {
    navigate('/make-order/')
  }

  return <MobileMenu
    onClickLogIn={onClickLogInHandler}
    isUserLoggedIn={isUserLoggedIn}
    onClickLogout={onClickLogoutHandler}
    onClickOrder={onClickOrderHandler}
    hasOrder={hasOrder}
  />
}
