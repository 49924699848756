import React from 'react'
import { Logo } from 'components/common'
import cn from 'classnames'
import css from './style.module.scss'
import {
  IconFacebook,
  IconJewishRuLogo,

} from 'components/icons'
import imgGolovaLogo from './img/golova.svg'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import fjc_1x from './img/fjc@1x.png'
import fjc_2x from './img/fjc@2x.png'

FooterView.propTypes = {
  onClickFeedback: PropTypes.func.isRequired
}

export default function FooterView({
  onClickFeedback
}) {
  return (
    <div className={css.footer}>
      <div className='container'>
        <div className={css.top}>
          <div className={css.topLeft}>
            <div className={css.logo}>
              <Link to="/" className={css.logoImage}>
                <Logo white />
              </Link>
            </div>
            <div className={css.socials}>
              {/*
              <div className={css.social}>
                <a href="https://www.facebook.com/FJCofCISRU/" target="_blank" rel="noopener noreferrer">
                  <IconFacebook />
                  &nbsp;
                  Facebook
                </a>
              </div>
              */}
            </div>
            <div className={css.contacts}>
              <button
                onClick={onClickFeedback}
                className={cn(css.contact, css.contactLink, 'label-2 white a')}
              >
                Связаться с нами
              </button>
              <Link
                to='/contacts'
                className={cn(css.contact, css.contactLink, 'label-2 white a')}
              >
                Контакты
              </Link>
              <Link
                to='/donate'
                className={cn(css.contact, 'button --ghost')}
              >
                Поддержать проект
              </Link>
            </div>
          </div>
          <div className={css.topRight}>
            <div className={css.jewish}>
              <div className={css.logo}>
                <a href="https://jewish.ru/" target="_blank" rel="noopener noreferrer">
                  <IconJewishRuLogo />
                </a>
              </div>
              <a
                href='https://fjc-fsu.org/'
                target='_blank'
                rel='noopener noreferrer'
                className={css.logo}
              >
                <img
                  src={fjc_1x}
                  srcSet={`${fjc_1x} 1x, ${fjc_2x} 2x`}
                  alt=''
                />
              </a>
            </div>
          </div>
        </div>
        <div className={css.bottom}>
          <div className={css.bottomLeft}>
            <div className={css.legal}>
              <p className={cn(css.copyright, 'caption white')}>
                ©{ (new Date()).getFullYear() }, ШАБАТ ШАЛОМ
              </p>
              <p className={css.legalPage}>
                <a
                  href="/privacy-policy/"
                  target="_blank"
                  className='white caption'
                  rel='noopener noreferrer'
                >
                  Политика обработки персональных данных
                </a>
              </p>

              <p className={css.legalPage}>
                <a
                  href="/terms-of-service/"
                  target="_blank"
                  className='white caption'
                  rel='noopener noreferrer'
                >
                  Пользовательское соглашение
                </a>
              </p>

              <p className={css.legalPage}>
                <a
                  href="/cookies-policy/"
                  target="_blank"
                  className='white caption'
                  rel='noopener noreferrer'
                >
                  Правила cookie
                </a>
              </p>
            </div>
          </div>
          <div className={css.bottomRight}>
            <div className={cn(css.golova, 'caption')}>
              Разработано в&nbsp;&nbsp;
              <a
                href='https://golovamedia.com/'
                target='_blank'
                rel='noreferrer'
              >
                <img src={imgGolovaLogo} alt='Golova Media'/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
