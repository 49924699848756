import React from 'react'
import { Link } from 'gatsby'

import css from './style.module.scss'

import img1 from './img/1.svg'
import img2 from './img/2.svg'
import img3 from './img/3.svg'

export default function({
  openSignUpPopup,
}) {
  const steps = [
    {
      text: <React.Fragment>Пройдите простую <Link to='/make-order'>регистрацию</Link> на сайте</React.Fragment>,
      icon: img1,
    },
    {
      text: <React.Fragment>Оставьте заявку, чтобы получить мацу для себя или для своих близких</React.Fragment>,
      icon: img2,
    },
    {
      text: <React.Fragment>Получите посылку и отметьте праздник</React.Fragment>,
      icon: img3,
    },
  ]
  return (
    <div className={css.holidaySet}>
      <div className='container'>
        <h2 className={css.title}>МАЦА ШМУРА к празднику Песах</h2>
        <div className={css.steps}>
          {
            steps.map((item, index) => (
              <React.Fragment
                key={index}
              >
                <div className={css.step}>
                  <div className={css.stepIcon}>
                    <img src={item.icon} alt=''/>
                  </div>
                  <p className={css.stepText}>{ item.text }</p>
                </div>
                {
                  index < steps.length - 1 &&
                    <div
                      className={css.stepArrow}
                      alt=''
                    />
                }
              </React.Fragment>
            ))
          }
        </div>
      </div>
    </div>
  )
}