import React from 'react'
import css from './style.module.scss'
import cn from 'classnames'
import { Button } from 'components/ui'
import { Logo, MobileMenu } from 'components/common'
import PropTypes from 'prop-types'
import { UserDropdown } from 'components/user'
import { isNil } from 'ramda'
import { Link } from 'gatsby'

HeaderView.propTypes = {
  onClickLogIn: PropTypes.func.isRequired,
  isUserLoggedIn: PropTypes.oneOfType([PropTypes.bool.isRequired])
}

export default function HeaderView({
  onClickLogIn,
  isUserLoggedIn
}) {

  const AuthButtons = () => (
    <div className={cn(css.section2, 'desktop')}>
      <div className={css.auth}>
        <div
          className={cn(css.login, 'label-2')}
          onClick={onClickLogIn}
          role='button'
          tabIndex='0'
          onKeyDown={() => {}}
        >
          Войти
        </div>
      </div>
    </div>
  )

  return (
    <header className={css.header}>
      <div className={cn(css.container, 'container')}>
        <div className={css.section1}>
          <Link to="/" aria-label="check">{/*TODO: fix aria-label*/}
            <Logo />
          </Link>
          <div className={cn(css.linkWrapper, 'label-2 desktop')}>
            <Link to="/donate" className={css.link} aria-label="check">Поддержать проект</Link>
          </div>
          <div className={cn(css.linkWrapper, 'label-2 desktop')}>
            <a
              href="https://shabbatshalom.ru/archive/"
              rel="noopener noreferrer"
              target="_blank"
              className={css.link}
              aria-label="check"
            >
              Архив 
            </a>
          </div>
          {/* <div className={cn(css.linkWrapper, 'label-2 desktop')}>
            <Link to="/reviews" className={css.link} aria-label="check">Отзывы</Link>
          </div> */}
        </div>

        <div className='desktop'>
          <div className={css.section2}>
            {!isNil(isUserLoggedIn) && isUserLoggedIn ? <UserDropdown /> : <AuthButtons />}

            <Link to='/make-order/'>
              <Button
                text="Оставить заявку"
                blue
                ghost
                smaller
                className={css.signUp}
              />
            </Link>
          </div>
        </div>

        <div className="mobile">
          <MobileMenu />
        </div>
      </div>
    </header>
  )
}
