import React from 'react'
import { useFormik } from 'formik'
import axios from 'axios'
import * as Yup from 'yup'

import { API_URL } from 'src/config'
import { fieldNames } from '../constants'
import FeedbackFormView from './FeedbackFormView'

export default UserFeedbackContainer

function UserFeedbackContainer({
  onSuccess
}) {
  const formik = useFormik({
    initialValues: {
      [fieldNames.NAME]: '',
      [fieldNames.EMAIL]: '',
      [fieldNames.MESSAGE]: ''
    },

    validationSchema: Yup.object().shape({
      [fieldNames.NAME]: Yup.string().required('Обязательное поле'),
      [fieldNames.EMAIL]: Yup.string().email('Invalid email').required('Обязательное поле'),
      [fieldNames.MESSAGE]: Yup.string().required('Обязательное поле')
    }),

    onSubmit: values => {
      axios({
        method: 'post',
        data: {...values},
        url: `${API_URL}/feedback/`
      }).then(res => {
        if (onSuccess) {
          onSuccess()
        }
        console.log(res)
      }).catch(err => {
        console.error(err)
      })
    }
  })

  return <FeedbackFormView
    values={formik.values}
    errors={formik.errors}
    touched={formik.touched}
    handleChange={formik.handleChange}
    handleSubmit={formik.handleSubmit}
  />
}
