import React, { useEffect } from 'react'
import { popupTypes } from './actions'
import PopupLayout from './PopupLayout/PopupLayout'
import PopupAlert from './PopupAlert/PopupAlert'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import css from './style.module.scss'

const POPUPS = {
  [popupTypes.POPUP_LAYOUT]: PopupLayout,
  [popupTypes.POPUP_ALERT]: PopupAlert
}

function PopupRoot(popups) {
  useEffect(() => {
    Modal.setAppElement('body')
  }, [])
  
  if (!popups.popupType)
    return null

  const SpecificPopup = POPUPS[popups.popupType]


  return (
    <Modal
      isOpen={true}
      overlayClassName={css.overlay}
      className={css.content}
    >
      <SpecificPopup {...popups.popupProps} />
    </Modal>
  )
}

export default connect(state => state.popups)(PopupRoot)
