import React, { Component } from 'react'
import css from './style.module.scss'
import { slide as Menu } from 'react-burger-menu'
import { Link } from "gatsby"
import { IconMenu, IconCross } from 'components/icons' 
import cn from 'classnames'
import { Logo } from 'components/common'
import PropTypes from 'prop-types'
import { isNil } from 'ramda'

export default class MobileMenu extends Component {

  showSettings (event) {
    event.preventDefault()
  }

  state = {
    menuOpen: false
  }

  handleStateChange(state) {
    this.setState({menuOpen: state.isOpen})  
    window.setTimeout(() => { 
      if (state.isOpen) {
        document.activeElement.blur()
      }
    })
  }
  closeMenu () {
    this.setState({menuOpen: false})
  }
  toggleMenu () {
    this.setState(state => ({menuOpen: !state.menuOpen}))
  }
  
  render() {
    const {
      onClickLogIn,
      isUserLoggedIn,
      onClickLogout,
      onClickOrder,
      hasOrder,
    } = this.props

    const AuthButtons = () => (
      <div className={css.authButtons}>
        <div
          className={cn('label-2 primary-color', css.logIn)}
          onClick={() => {
            this.closeMenu()
            onClickLogIn()
          }}
          role='button'
          tabIndex='0'
          onKeyDown={() => {}}
        >
          Войти
        </div>
      </div>
    )
    
    const MobileMenuAuth = () => (
      <ul className={css.authList}>
        <li>
          <Link
            to="/account/"
            className="label-2 primary-color"
            onClick={() => this.closeMenu()}
          >
            Личные данные
          </Link>
        </li>
        <li>
          <Link
            to="/account/settings/"
            className="label-2 primary-color"
            onClick={() => this.closeMenu()}
          >
            Настройки аккаунта
          </Link>
        </li>
        <li>
          <span
            onClick={() => {
              this.closeMenu()
              onClickLogout()
            }}
            className="label-2 pointer primary-color"
            role='button'
            tabIndex='0'
            onKeyDown={() => {}}
          >
            Выйти
          </span>
        </li>
      </ul>
    )
    
    return (
      <Menu className={css.mobileMenu} 
            isOpen={this.state.menuOpen}
            onStateChange={(state) => this.handleStateChange(state)} 
            right 
            customBurgerIcon={ <IconMenu /> }
            customCrossIcon={ <IconCross /> }
            burgerButtonClassName={css.mobileMenuButton}
            overlayClassName={css.mobileMenuOverlay}
            menuClassName={css.mobileMenuBody}
            itemListClassName={css.mobileMenuList}
            crossButtonClassName={css.mobileMenuCrossButton}
      >
        <div className={css.mobileMenuHeader}>
          <Link to="/" 
            aria-label="check"
            className={css.mobileLogo}
            onClick={() => this.closeMenu()}
          >
            <Logo />
          </Link>
        </div>
        <Link to='/donate' 
          className={cn(css.menuItem, 'label-2')} 
          onClick={() => this.closeMenu()}
        >
          Поддержать проект 
        </Link>
        <a
          href="https://shabbatshalom.ru/archive/"
          rel="noopener noreferrer"
          target="_blank"
          className={cn(css.menuItem, css.lastItem, 'label-2')} 
        >
          Архив 
        </a>
        {/* <Link to='/reviews' 
          className={cn(css.menuItem, css.lastItem, 'label-2')} 
          onClick={() => this.closeMenu()}
        >
          Отзывы 
        </Link> */}
        {
          hasOrder &&
            <span
              className={cn(css.menuItem, css.lastItem, 'label-2 primary-color')} 
              onClick={() => {
                this.closeMenu()
                onClickOrder()
              }}
              role='button'
              tabIndex='0'
              onKeyDown={() => {}}
            >
              Оставить заявку на получение набора
            </span>
        }

        {!isNil(isUserLoggedIn) && isUserLoggedIn ? <MobileMenuAuth /> : <AuthButtons />}

      </Menu>
    )
  }
}

MobileMenu.propTypes = {
  onClickLogIn: PropTypes.func.isRequired,
  isUserLoggedIn: PropTypes.oneOfType([PropTypes.bool.isRequired])
}