import React from 'react'
// import axios from 'axios'
// import { navigate } from 'gatsby'

import { openPopup, closePopup, popupTypes } from 'components/popups/actions'
// import { API_URL } from 'src/config'
import FeedbackForm from './components/FeedbackFormContainer'

export function openFeedbackPopup() {
  return dispatch => {
    dispatch(openPopup(popupTypes.POPUP_LAYOUT, {
      title: 'Связаться с нами',
      children: (
        <FeedbackForm
          onSuccess={() => dispatch(openFeedbackSuccessPopup())}
        />
      )
    }))
  }
}

export function openFeedbackSuccessPopup() {
  return dispatch => {
    dispatch(openPopup(popupTypes.POPUP_ALERT, {
      title: 'Ваше сообщение отправлено',
      text: 'Наши менеджеры ответят на ваш запрос в ближайшее время',
      buttonText: 'Ок',
      onClickButton: () => {
        dispatch(closePopup())
      }
    }))
  }
}