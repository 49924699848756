import React, { useState } from 'react'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import ModalVideo from 'react-modal-video'

import yt_1_1x from './img/yt_1@1x.jpg'
import yt_1_2x from './img/yt_1@2x.jpg'
import yt_2_1x from './img/yt_2@1x.jpg'
import yt_2_2x from './img/yt_2@2x.jpg'
import yt_3_1x from './img/yt_3@1x.jpg'
import yt_3_2x from './img/yt_3@2x.jpg'
import yt_4_1x from './img/yt_4@1x.jpg'
import yt_4_2x from './img/yt_4@2x.jpg'
import css from './style.module.scss'

const videos = [
  {
    id: 'uXTPzqlJRv8',
    x1: yt_1_1x,
    x2: yt_1_2x,
    title: 'История мацы',
  },
  {
    id: 'XUsjWjayDw8',
    x1: yt_2_1x,
    x2: yt_2_2x,
    title: 'Поиски и уничтожение Хамеца',
  },
  {
    id: 'WA4cCHy75Sg',
    x1: yt_3_1x,
    x2: yt_3_2x,
    title: 'Как провести пасхальный Седер',
  },
  {
    id: 'XhxmzDGOLiA',
    x1: yt_4_1x,
    x2: yt_4_2x,
    title: 'Как кошеровать кухню к Песаху',
  }
]

export default function () {
  const [vid, setVid] = useState('')

  return (
    <div className={css.relatedVideo}>
      <div className='container'>
        <h2 className={css.title}>Советуем посмотреть</h2>
        <ModalVideo
          channel='youtube'
          autoplay
          isOpen={ vid.length > 0 }
          videoId={ vid }
          onClose={() => setVid('')}
        />
        <Container>
          <Row justifyContent='center'>
            {
              videos.map((video, idx) => (
                <Col
                  col='12'
                  md='6'
                  lg='3'
                  key={idx}
                >
                  <div className={css.card}>
                    <button
                      className={css.videoButton}
                      onClick={() => {setVid(video.id)}}
                    >
                      <img
                        src={ video.x1 }
                        srcSet={`${ video.x1 } 1x, ${ video.x2 } 2x`}
                        className={css.image}
                        alt=''
                      />
                    </button>
                    <h5>{ video.title }</h5>
                  </div>
                </Col>
              ))
            }
          </Row>
        </Container>
      </div>
    </div>
  )
}

// История мацы
// https://youtu.be/uXTPzqlJRv8

// Поиски и уничтожение Хамеца
// https://youtu.be/XUsjWjayDw8

// Как провести пасхальный Седер
// https://youtu.be/WA4cCHy75Sg

// Как кошеровать кухню к Песаху
// https://youtu.be/XhxmzDGOLiA