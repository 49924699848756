import React from 'react'
import { Link } from 'gatsby'
import { connect } from 'react-redux'
import cn from 'classnames'

import { Button } from 'components/ui'
import css from './style.module.scss'

const mapStateToProps = state => ({
  hasOrder: state.orders.has_order,
})

export default connect(mapStateToProps, null)(HolidaySetBanner)

function HolidaySetBanner({
  hasOrder,
}) {
    return (
    <div className={css.banner}>
      <div className={cn('container', css.content)}>
        <div className={css.titleWrapper}>
          {
            hasOrder &&
              <h2 className={css.title}>Получите мацу ручной работы к празднику</h2>
          }
          {
            !hasOrder &&
              <h2 className={css.title}>Заявки больше не принимаются</h2>
          }
        </div>
        {
          hasOrder &&
            <div className={css.buttonWrap}>
              <Link to='/make-order/'>
                <Button
                  className={css.button}
                  text="Оставить заявку"
                />
              </Link>
            </div>
        }
      </div>
    </div>
  )
}
