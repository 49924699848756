import React from 'react'
import HeroView from './View'
import { connect } from 'react-redux'

HeroContainer.propTypes = {
}

const mapStateToProps = state => ({
  hasOrder: state.orders.has_order,
})

export default connect(
  mapStateToProps,
  null
)(HeroContainer)

function HeroContainer({
  hasOrder,
}) {
  return <HeroView
    hasOrder={hasOrder}
  />
}
