import React from 'react'
import css from './style.module.scss'
import { Button } from 'components/ui'

export default function({
  errorNumber,
  errorTitle,
  errorText,
  errorButton
}) {
  return (
    <div className={css.systemPageMessage}>
      <p className='label-2'>{`Ошибка ${errorNumber}`}</p>
      <h2 className={css.errorTitle}>{ errorTitle }</h2>
      <p>{ errorText }</p>
      
      {
        errorButton && 
        <div className={css.errorButton}>
          <Button text={'На главную'}/>
        </div>
      }
    </div>
  )
}
