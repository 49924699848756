import React from 'react'
import ReactPaginate from 'react-paginate'
import css from './style.module.scss'
import arrow from './img/arrow.svg'

export default function Pagination({
  pageCount,
  pageRangeDisplayed,
  marginPagesDisplayed,
  onPageChange,
  initialPage,
}) {
  return (
    <ReactPaginate
      pageCount={pageCount || 1}
      pageRangeDisplayed={pageRangeDisplayed || 1}
      marginPagesDisplayed={marginPagesDisplayed || 1}
      onPageChange={onPageChange}
      initialPage={initialPage}

      previousLabel={<img src={arrow} alt='previous' />}
      nextLabel={<img src={arrow} alt='next' />}
      
      containerClassName={css.pagination}
      pageClassName={css.page}
      pageLinkClassName={css.pageLink}
      activeClassName={css.active}
      activeLinkClassName={css.activeLink}
      previousClassName={css.previous}
      nextClassName={css.next}
      previousLinkClassName={css.previousLink}
      nextLinkClassName={css.nextLink}
      disabledClassName={css.disabled}
      breakClassName={css.break}
      breakLinkClassName={css.breakLink}
    />
  )
}