import React from 'react'

import { IconLinkAway } from 'components/icons'
import css from './sale.module.scss'

export default function() {
  return (
    <div className='container'>
      <div className={css.sale}>
        <h2 className={css.title}>Продажа хамеца</h2>
        <p className={css.description}>В Песах запрещен хамец – мучные изделия, изготовленные путем заквашивания. Хамец не только нельзя есть – им запрещено владеть. Если в доме есть запасы хамеца, следует оформить их продажу.</p>
        <div className={css.linkWrapper}>
          <a
            href='http://ravvinat.ru/ru/general_information/chometz/'
            target='_blank'
            rel='noopener noreferrer'
            className='label blue'
          >
            <IconLinkAway blue style={{ verticalAlign: 'text-top' }}/>&nbsp;&nbsp;Узнайте подробнее
          </a>
        </div>
      </div>
    </div>
  )
}