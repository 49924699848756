import React from 'react'
import css from './style.module.scss'
import { InputText, Button, Textarea } from 'components/ui'
import cn from 'classnames'
import { fieldNames } from '../constants'
import PropTypes from 'prop-types'

UserFeedback.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  onClickLogIn: PropTypes.func.isRequired
}

export default function UserFeedback({
  handleChange,
  handleSubmit,
  values,
  onChangeSubject,
  errors,
  touched
}) {
  return (
    <div className={cn(css.userFeedback, 'form-layout')}>
      <form onSubmit={handleSubmit}>
        <div className='form-field'>
          <InputText
            placeholder="Имя"
            name={fieldNames.NAME}
            value={values[fieldNames.NAME]}
            onChange={handleChange}
            error={touched[fieldNames.NAME] && errors[fieldNames.NAME]}
          />
        </div>

        <div className='form-field'>
          <InputText
            placeholder="E-mail"
            name={fieldNames.EMAIL}
            value={values[fieldNames.EMAIL]}
            onChange={handleChange}
            error={touched[fieldNames.EMAIL] && errors[fieldNames.EMAIL]}
          />
        </div>
        
        <div className={cn('form-field', css.textarea)}>
          <Textarea
            id="feedback"
            name={fieldNames.MESSAGE}
            onChange={handleChange}
            value={values[fieldNames.MESSAGE]}
            label="Сообщение"
            placeholder="Опишите вашу проблему или задайте вопрос"
            error={touched[fieldNames.MESSAGE] && errors[fieldNames.MESSAGE]}
          />
        </div>

        <div className="form-button">
          <Button text="Отправить" type="submit" />
        </div>

      </form>
    </div>
  )
}
