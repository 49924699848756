import React from 'react'
import FooterView from './View'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { openFeedbackPopup } from 'components/feedback/actions'
import PropTypes from 'prop-types'

FooterContainer.propTypes = {
  openFeedbackPopup: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => ({
  openFeedbackPopup: bindActionCreators(openFeedbackPopup, dispatch),
})

export default connect(
  null,
  mapDispatchToProps
)(FooterContainer)

function FooterContainer({
  openFeedbackPopup
}) {

  const onClickFeedbackHandler = () => {
    openFeedbackPopup()
  }

  return <FooterView
    onClickFeedback={onClickFeedbackHandler}
  />
}
